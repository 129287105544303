import Layout from "../../layout/layout";
import ActiveSubscribers from "./parts/activeSubscribers";

function DownloadsPage() {
    const body = (
        <>
            
            <div>
                <ActiveSubscribers />
            </div>
            
        </>
    );

    return <Layout body={body} title={"Downloads Page"} />;
}

export default DownloadsPage;
