import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  Box,
  CircularProgress
} from '@mui/material';
import axios from '../../../api/axios';

const SubscriptionDetailsDialog = ({
  open,
  onClose,
  onConfirm,
  subscriptionId,
  title = 'Subscription Details',
  confirmText = 'Confirm',
  message
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);

  useEffect(() => {
    const fetchSubscriptionDetails = async () => {
      if (open && subscriptionId) {
        setIsLoading(true);
        try {
          const response = await axios.get(`subscription/get/?subscription_id=${subscriptionId}`);
          setSubscriptionDetails(response.data.subscription);
        } catch (error) {
          console.error('Error fetching subscription details:', error);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchSubscriptionDetails();
    return () => {
      setSubscriptionDetails(null);
    };
  }, [open, subscriptionId]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress />
          </Box>
        ) : subscriptionDetails ? (
          <Grid container spacing={2}>
            {/* Package Details */}
            <Grid item xs={4}>
              <Typography variant="subtitle1" gutterBottom>Package Details</Typography>
              <Typography variant="body2">
                <strong>Category:</strong> {subscriptionDetails.package.category.name}
              </Typography>
              <Typography variant="body2">
                <strong>Duration:</strong> {subscriptionDetails.package.duration} days
              </Typography>
              <Typography variant="body2">
                <strong>Custom Package:</strong> {subscriptionDetails.package.is_custom ? 'Yes' : 'No'}
              </Typography>
            </Grid>

            {/* Meal Details */}
            <Grid item xs={4}>
              <Typography variant="subtitle1" gutterBottom>Daily Meals</Typography>
              <Typography variant="body2">
                <strong>Breakfast:</strong> {subscriptionDetails.package.daily_breakfast || 0}
              </Typography>
              <Typography variant="body2">
                <strong>Meals:</strong> {subscriptionDetails.package.daily_meal || 0}
              </Typography>
              <Typography variant="body2">
                <strong>Snacks:</strong> {subscriptionDetails.package.daily_snack || 0}
              </Typography>
              <Typography variant="body2">
                <strong>Sweets:</strong> {subscriptionDetails.package.daily_sweet || 0}
              </Typography>
              <Typography variant="body2">
                <strong>Protein Level:</strong> {subscriptionDetails.package.protein_level}%
              </Typography>
            </Grid>

            {/* Subscription Details */}
            <Grid item xs={4}>
              <Typography variant="subtitle1" gutterBottom>Subscription</Typography>
              <Typography variant="body2">
                <strong>Price:</strong> KWD {subscriptionDetails.package.price?.toFixed(2)}
              </Typography>
              <Typography variant="body2">
                <strong>Start Date:</strong> {subscriptionDetails.start_date}
              </Typography>
              <Typography variant="body2">
                <strong>End Date:</strong> {subscriptionDetails.end_date}
              </Typography>
            </Grid>
          </Grid>
        ) : null}
        
        {message && (
          <Typography variant="body1" color="error" sx={{ mt: 2 }}>
            {message}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        {/* <Button 
          onClick={onConfirm} 
          variant="contained" 
          color="primary"
          disabled={isLoading}
        >
          {confirmText}
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default SubscriptionDetailsDialog;