import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../../layout/layout";
import axios from "../../api/axios";


import { TextField, Box, Button, ToggleButtonGroup, ToggleButton, Snackbar, Alert, Grid, Card, Typography } from "@mui/material";
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"
import DataCard from "../../components/dataCard";
import { green } from "@mui/material/colors";
import { set } from "date-fns";
import { useLocation } from 'react-router-dom';

const minDate = dayjs().add(3, 'day');
function BuySubscription() {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const uID = params.get('userId');
    const [userID, setUserID] = useState(0);
    const [breakfastCount, setBreakfastCount] = useState(0);
    const [mealCount, setMealCount] = useState(1);
    const [snackCount, setSnackCount] = useState(0);
    const [sweetCount, setSweetCount] = useState(0);
    const [proteinLevel, setProteinLevel] = useState(100);
    const [price, setPrice] = useState(0);
    const [duration, setDuration] = useState(1);
    const [startDate, setStartDate] = useState(null);
    const [userName, setUserName] = useState(null);
    const [isValidID, setIsValidID] = useState(false);
    // isvalidpackage
    const [isValidPackage, setIsValidPackage] = useState(false);

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("success");
    const [is1100, setIs1100] = useState(false);
    useEffect(() => {
        if (uID) {
            setUserID(uID);
        }
    }, [uID]);

    useEffect(() => {
        // calculate price based on inputs
        var meal_price = 0
        if (proteinLevel === 100) {
            meal_price = 1.33;
        }
        else if (proteinLevel === 150) {
            meal_price = 1.62;
        }
        else if (proteinLevel === 200) {
            meal_price = 1.81;
        }
        var calculatedPrice = ((breakfastCount * .97) + (mealCount * meal_price) + (snackCount * .82) + (sweetCount * .65) + .36);
        calculatedPrice = Math.ceil(calculatedPrice * duration);
        setPrice(calculatedPrice);
        if (proteinLevel === 1100 || proteinLevel === 1250) {
            setIs1100(true);
            if (proteinLevel === 1100) {
                setPrice(65);
                setSnackCount(1);
                setSweetCount(1);
            } else {
                setPrice(85);
            }
            setMealCount(2);
            setBreakfastCount(1);
            
            setDuration(26);
        }
        else {
            setIs1100(false);
        }
    }, [breakfastCount, mealCount, snackCount, sweetCount, proteinLevel, duration]);

    useEffect(() => {
        // get username from user id
        getUsernameFromUserId(userID)
    }, [userID]);

    useEffect(() => {
        // convert all count to number
        console.log(dayjs(startDate).format('DD/MM/YYYY'))

        var temp = isValidID && mealCount > 0 && duration > 0 && startDate !== null && Number(mealCount) + Number(breakfastCount) + Number(snackCount) + Number(sweetCount) > 1;
        console.log("temp", temp);
        console.log("mealCount", Number(mealCount) + Number(breakfastCount) + Number(snackCount) + Number(sweetCount));
        temp = temp && dayjs(startDate).isAfter(minDate);
        setIsValidPackage(temp);
    }, [
        isValidID,
        mealCount,
        duration,
        startDate,
        breakfastCount,
        snackCount,
        sweetCount,
    ]);





    async function getUsernameFromUserId(userId) {
        try {
            const response = await axios.post("get_username_from_id/", {
                user_id: userId,
            });
            console.log(response.data);
            if (response.data.msg === "invalid user id") {
                setIsValidID(false);
                setUserName("");
            } else {
                setIsValidID(true);
                setUserName(response.data.msg);
            }

        } catch (error) {
            console.error(error);
            setIsValidID(false);
            setUserName("");
            return null;
        }
    }
    // createSubscription();
    async function createSubscription() {
        try {
            const response = await axios.post("buy_subscription/", {
                user_id: userID,
                daily_breakfast: breakfastCount,
                daily_meal: mealCount,
                daily_snacks: snackCount,
                daily_sweet: sweetCount,
                protein_level: proteinLevel,
                duration: duration,
                start_date: dayjs(startDate).format('YYYY-MM-DD'),
            });
            if (response.status === 200) {
                setAlertOpen(true);
                setAlertMessage(response.data.msg);
                setAlertSeverity("success");
            } else {
                setAlertOpen(true);
                setAlertMessage(response.data.msg);
                setAlertSeverity("error");
            }

        } catch (error) {
            console.error(error);
            return null;
        }
    }
    const handleAlertClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setAlertOpen(false);
    };


    const body = (
        <>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={handleAlertClose}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={alertSeverity}
                    sx={{ width: "100%" }}
                >
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label="User ID"
                        value={userID}
                        type="number"
                        onChange={(e) => setUserID(e.target.value)}
                    />

                    <> {(isValidID) ? "" : <h3 style={{ color: 'red' }}>Invalid User ID</h3>}
                        <h3 style={{ color: 'green' }}>{userName}</h3>
                    </>
                    <TextField
                        label="Breakfast Count"
                        sx={{ mb: 2 }}
                        value={breakfastCount}
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 }
                        }}

                        onChange={(e) => setBreakfastCount(Number(e.target.value))}
                    />

                </Grid>
                <Grid item xs={6}>

                    <Card sx={{ p: 2 , maxWidth:350}}>
                        <Typography variant="h4" color="primary">{price} KWD</Typography>
                        <Typography variant="p" color="textSecondary" >Breakfast: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{breakfastCount}</Typography>
                        <Typography variant="p" color="textSecondary">, Meal: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{mealCount}</Typography>
                        <Typography variant="p" color="textSecondary">, Snack: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{snackCount}</Typography>
                        <Typography variant="p" color="textSecondary">, Sweet: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{sweetCount}</Typography>
                        <br></br>
                        <Typography variant="p" color="textSecondary">Protein: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{proteinLevel}</Typography>
                        <Typography variant="p" color="textSecondary">, Duration: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{duration} days</Typography>
                        <br></br>
                        <Typography variant="p" color="textSecondary">Start Date: </Typography>
                        <Typography variant="p" color="primary" fontWeight="bold">{dayjs(startDate).format('YYYY-MM-DD')}</Typography>

                    </Card>

                </Grid>

                <Grid item xs={6}>
                    <TextField
                        label="Meal Count"
                        sx={{ mb: 2 }}
                        value={mealCount}
                        type="number"
                        InputProps={{
                            inputProps: { min: 1 }
                        }}
                        onChange={(e) => setMealCount(Number(e.target.value))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Snack Count"
                        sx={{ mb: 2 }}
                        value={snackCount}
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        onChange={(e) => setSnackCount(Number(e.target.value))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Sweet Count"
                        value={sweetCount}
                        type="number"
                        InputProps={{
                            inputProps: { min: 0 }
                        }}
                        onChange={(e) => setSweetCount(
                            Number(e.target.value))}
                    />
                </Grid>

                <Grid item xs={6}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}>
                        <DatePicker
                            disableToolbar
                            variant="inline"
                            format="DD/MM/YYYY"
                            label="Start Date"
                            value={startDate}
                            onChange={(date) => setStartDate(date)}
                            fullWidth
                            renderInput={(props) => <TextField sx={{ mt: 2,  width: 220 }}{...props} fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                    <p>Protein level</p>
                    <ToggleButtonGroup
                        color="primary"
                        value={proteinLevel.toString()}
                        exclusive
                        onChange={(e) => setProteinLevel(Number(e.target.value))}
                        aria-label="Protein Level"
                    >
                        <ToggleButton value="100">100</ToggleButton>
                        <ToggleButton value="150">150</ToggleButton>
                        <ToggleButton value="200">200</ToggleButton>
                        <ToggleButton value="1100">1100</ToggleButton>
                        <ToggleButton value="1250">1250</ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                <Grid item xs={6}>
                    <p>Duration</p>

                    <ToggleButtonGroup
                        color="primary"
                        value={duration.toString()}
                        exclusive
                        onChange={(e) => setDuration(Number(e.target.value))}
                        aria-label="Duration"
                    >
                        <ToggleButton value="1" disabled={is1100}>1</ToggleButton>
                        <ToggleButton value="6" disabled={is1100}>6</ToggleButton>
                        <ToggleButton value="12" disabled={is1100}>12</ToggleButton>
                        <ToggleButton value="14" disabled={is1100}>14</ToggleButton>
                        <ToggleButton value="26">26</ToggleButton>
                        <ToggleButton value="20" disabled ={is1100}>20 ( No weekened ) </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>

                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        disabled={!isValidPackage}
                        onClick={() => {
                            // call api to create subscription
                            createSubscription();
                        }}
                    >
                        Create Subscription
                    </Button>
                </Grid>
            </Grid>
        </>
    )

    return (
        <Layout
            body={
                body
            }
            title="Buy Subscription" />
    );
}


export default BuySubscription;
