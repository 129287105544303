import "./App.css";
import React from "react";
import LoginPage from "./pages/loginPage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./pages/dashboard/dashboard";
import UsersPage from "./pages/users/usersPage";
import EnhancedTable from "./pages/users/temp";
import ProtectedPage from "./pages/ProtectedPage";
import CouponList from "./pages/coupon/coupon-list";
import CreateCoupon from "./pages/coupon/create-coupon";
import BuySubscription from "./pages/subscription/buy-subscription";
import OrderListPage from "./pages/orderList/orderListPage";
import MealsPage from "./pages/meals/mealsPage";
import TagsPage from "./pages/meals/tag/tagsPage";
import DishList from "./pages/meals/dish/dishListPage";
import CreateDish from "./pages/meals/dish/create-dish";
import MenuPage from "./pages/meals/menu/menuPage";
import EventsPage from "./pages/event/events-page";
import CreateEvent from "./pages/event/event/create-event";
import EventDishCategoriesPage from "./pages/event/category/categories-page";
import CreateEventDishCategory from "./pages/event/category/create-category";
import CreateEventDish from "./pages/event/dish/create-event-dish";
import EventDishesPage from "./pages/event/dish/event-dishes-page";
import EventSelectionPage from "./pages/subscription/event-selection";
import BuyEventSubscription from "./pages/subscription/buy-event-subscription";
import CreateMenuPage from "./pages/meals/menu/createMenu";
import ShowMenu from "./pages/meals/menu/showMenu";
import DownloadsPage from "./pages/downloads/downloadsPage";
import UserManagement from './pages/userManagement/userManagement'; 
import DishManagement from './pages/kitchen_management/dishes/dishManagement';
import KitchenManagement from "./pages/kitchen_management/kitchenManagement";
import IngredientsListPage from "./pages/kitchen_management/ingredients/ingredientsListPage";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#649230",
    },
    secondary: {
      main: "#8B8B8A",
    },
    // background: {
    //   default: "#161615",
    //   paper: "#2D2D2C",
    // },
  },
  // typography: {
  //   fontfamily: "Nunito",
  //   fontWeightLight: 300,
  //   fontWeightRegular: 400,
  //   fontWeightMedium: 600,
  //   fontWeightBold: 700,
  // },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedPage>
              <Dashboard />
            </ProtectedPage>
          }
        />
        <Route
          path="/users"
          element={
            <ProtectedPage>
              <UsersPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/meh"
          element={
            <ProtectedPage>
              <EnhancedTable />
            </ProtectedPage>
          }
        />
        <Route
          path="/create-coupon"
          element={
            <ProtectedPage>
              <CreateCoupon />
            </ProtectedPage>
          }
        />
        <Route
          path="/coupon-list"
          element={
            <ProtectedPage>
              <CouponList />
            </ProtectedPage>
          }
        />
        <Route
          path="/buy-subscription"
          element={
            <ProtectedPage>
              <BuySubscription />
            </ProtectedPage>
          }
        /><Route
          path="/buy-event-subscription"
          element={
            <ProtectedPage>
              <BuyEventSubscription />
            </ProtectedPage>
          }
        />
        <Route
          path="/order-list-page"
          element={
            <ProtectedPage>
              <OrderListPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/meals-page"
          element={
            <ProtectedPage>
              <MealsPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/tags"
          element={
            <ProtectedPage>
              <TagsPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/dishes/"
          element={
            <ProtectedPage>
              <DishList />
            </ProtectedPage>
          }
        />
        <Route
          path="/dishes/v2"
          element={
            <ProtectedPage>
              <DishManagement />
            </ProtectedPage>
          }
        />
        <Route
          path="/create-dish"
          element={
            <ProtectedPage>
              <CreateDish />
            </ProtectedPage>
          }
        />


        <Route
          path="/menu"
          element={
            <ProtectedPage>
              <MenuPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/menu/create"
          element={
            <ProtectedPage>
              <CreateMenuPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/menu/show"
          element={
            <ProtectedPage>
              <ShowMenu />
            </ProtectedPage>
          }
        />
                <Route
          path="/downloads"
          element={
            <ProtectedPage>
              <DownloadsPage />
            </ProtectedPage>
          }
        />

        <Route
          path="/events"
          element={
            <ProtectedPage>
              <EventsPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/create-event"
          element={
            <ProtectedPage>
              <CreateEvent />
            </ProtectedPage>
          }
        />
        <Route
          path="/event-dish-categories"
          element={
            <ProtectedPage>
              <EventDishCategoriesPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/create-event-dish-category"
          element={
            <ProtectedPage>
              <CreateEventDishCategory />
            </ProtectedPage>
          }
        />
        <Route
          path="/create-event-dish"
          element={
            <ProtectedPage>
              <CreateEventDish />
            </ProtectedPage>
          }
        />
        <Route
          path="/event-dishes"
          element={
            <ProtectedPage>
              <EventDishesPage />
            </ProtectedPage>
          }
        />
        {/* event-selction */}
        <Route
          path="/event-selection"
          element={
            <ProtectedPage>
              <EventSelectionPage />
            </ProtectedPage>
          }
        />
        <Route
          path="/user-management"
          element={
            <ProtectedPage>
              <UserManagement />
            </ProtectedPage>
          }
        />
        <Route
          path="/kitchen-management"
          element={
            <ProtectedPage>
              <KitchenManagement/>
            </ProtectedPage>
          }
        />
        <Route
          path="/ingredient-management"
          element={
            <ProtectedPage>
              <IngredientsListPage />
            </ProtectedPage>
          }
        />
        
        <Route path="*" element={<div>404 Not Found</div>} />

      </Routes>
    </ThemeProvider>
  );
}

export default App;
