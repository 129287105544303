import { Button } from "@mui/material";
import Layout from "../../layout/layout";
import { useNavigate } from "react-router-dom";
function MealsPage() {

    const navigate = useNavigate();
    const body = (
        <div>
            <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2 }}
                onClick={() => {
                navigate("/tags", { replace: true});
            }}>
                Tags
            </Button>
            <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2 }}
                onClick={() => {
                    navigate("/dishes", { replace: true });
                }}>
                Dishes
            </Button>
            <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2 }}
                onClick={() => {
                    navigate("/menu", { replace: true });
                }}>
                Copy Menu
            </Button>
            <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2 }}
                onClick={() => {
                    navigate("/menu/create", { replace: true });
                }}>
                Create Menu
            </Button>
            <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2 }}
                onClick={() => {
                    navigate("/menu/show", { replace: true });
                }}>
                Show Menu
            </Button>
            <Button variant="contained" color="primary" sx={{ mr: 2, mb: 2 }}
                onClick={() => {
                    navigate("/dishes/v2/", { replace: true });
                }}>
                Dish Options   
            </Button>
        </div>
    );
    return <Layout body={body} title={"Meals"} />;

}

export default MealsPage;
