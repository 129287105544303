import React, { useState, useEffect, useRef } from 'react';
import axios from '../../../api/axios';
import Layout from '../../../layout/layout';
import {
    Box,
    TextField,
    List,
    ListItem,
    ListItemText,
    Typography,
    Divider,
    Button,
    Grid,
    Paper,
    CircularProgress,
    IconButton,
    Pagination,
    Snackbar,
    Alert,
    Autocomplete,
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
// import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useNavigate } from 'react-router-dom';

function DishManagement() {
    const [query, setQuery] = useState('');
    const [dishes, setDishes] = useState([]);
    const [selectedDish, setSelectedDish] = useState(null);
    const [dishDetails, setDishDetails] = useState(null);
    const [ingredientsInput, setIngredientsInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const debounceRef = useRef(null);
    const [allIngredients, setAllIngredients] = useState([]);
    const [selectedIngredients, setSelectedIngredients] = useState([]);

    const ITEMS_PER_PAGE = 30; // Adjust based on your API's pagination

    const handleSearch = (e) => {
        const value = e.target.value;
        setQuery(value);
        setCurrentPage(1); // Reset to first page on new search

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            if (value.length > 2) {
                fetchDishes(1, value);
            } else {
                fetchDishes(1, '');
            }
        }, 500); // 500ms debounce
    };

    const fetchDishes = async (page = 1, searchQuery = '') => {
        setLoading(true);
        try {
            let url = `dishes/search/?page=${page}`;
            if (searchQuery) {
                url += `&name=${encodeURIComponent(searchQuery)}`;
            }
            const response = await axios.get(url);
            setDishes(response.data.results);
            setCount(response.data.count);
            setTotalPages(Math.ceil(response.data.count / ITEMS_PER_PAGE));
        } catch (error) {
            console.error('Error fetching dishes:', error);
            setSnackbar({ open: true, message: 'Failed to fetch dishes.', severity: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const fetchDishDetails = async (dishId) => {
        try {
            const response = await axios.get(`dishes/${dishId}/`);
            setDishDetails(response.data);
        } catch (error) {
            console.error('Error fetching dish details:', error);
            setSnackbar({ open: true, message: 'Failed to fetch dish details.', severity: 'error' });
        }
    };

    useEffect(() => {
        fetchDishes(currentPage, query);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage]);

    useEffect(() => {
        // Safely handle null values for dishDetails and ingredients
        setIngredientsInput(dishDetails?.ingredients?.join(', ') || '');
    }, [dishDetails]);

    const fetchAllIngredients = async () => {
        try {
            const response = await axios.get('ingredient/list/');
            // Store the ingredient names in an array
            const names = response.data.map((ingredient) => ingredient.name);
            console.log(names);
            setAllIngredients(names);
        } catch (error) {
            console.error('Error fetching all ingredients:', error);
        }
    };
    useEffect(() => {

        fetchAllIngredients();
    }, []);

    useEffect(() => {
        const initIngredients = dishDetails?.ingredients || [];
        setSelectedIngredients(initIngredients);
    }, [dishDetails]);

    useEffect(() => {
        setIngredientsInput(selectedIngredients.join(', '));
    }, [selectedIngredients]);

    const handleDishClick = (dish) => {
        setSelectedDish(dish);
        fetchDishDetails(dish.id);
    };

    const handleEdit = (dish) => {
        navigate(`/edit-dish?dishId=${dish.id}`);
    };

    const handleDelete = async (dish) => {
        if (window.confirm(`Are you sure you want to delete ${dish.name}?`)) {
            try {
                await axios.delete(`dishes/v2/${dish.id}/`);
                fetchDishes(currentPage, query);
                setSnackbar({ open: true, message: 'Dish deleted successfully.', severity: 'success' });
            } catch (error) {
                console.error('Error deleting dish:', error);
                setSnackbar({ open: true, message: 'Failed to delete dish.', severity: 'error' });
            }
        }
    };

    const handleUpdateIngredients = async () => {
        // Split the ingredients by commas and trim whitespace
        const updatedIngredients = ingredientsInput.split(',').map(item => item.trim()).filter(item => item);
        console.log(updatedIngredients);

        try {
            await axios.post(`dishes/${dishDetails.id}/update-ingredients/`, {
                ...dishDetails,
                ingredients: updatedIngredients,
            });
            setSnackbar({ open: true, message: 'Ingredients updated successfully!', severity: 'success' });
            // Refresh dish details
            fetchDishDetails(dishDetails.id);
            fetchAllIngredients();
        } catch (error) {
            console.error('Error updating ingredients:', error);
            setSnackbar({ open: true, message: 'Failed to update ingredients.', severity: 'error' });
        }
    };

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const dishList = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                paddingRight: 1, // Optional: Add some padding for better UX
            }}
        >
            <TextField
                fullWidth
                value={query}
                onChange={handleSearch}
                placeholder="Search by name or ID"
                variant="outlined"
                margin="normal"
            />
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <List>
                        {dishes.map((dish) => (
                            <ListItem
                                button
                                key={dish.id}
                                onClick={() => handleDishClick(dish)}
                                selected={selectedDish && selectedDish.id === dish.id}
                            >
                                <ListItemText
                                    primary={dish.name}
                                    secondary={`ID: ${dish.id}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                    {/* Pagination Controls */}
                    {totalPages > 1 && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                mt: 2,
                            }}
                        >
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                            />
                        </Box>
                    )}
                </>
            )}
        </Box>
    );

    const dishDetailsSection = (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                overflowY: 'auto',
                padding: 2,
            }}
        >
            {dishDetails ? (
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            mb: 2,
                        }}
                    >
                        <Typography variant="h5">{dishDetails.name}</Typography>
                        {/* 
            TODO
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(`/edit-dish?dishId=${dishDetails.id}`)}
            >
              Edit Dish
            </Button> */}
                    </Box>
                    <Divider sx={{ my: 1 }} />

                    <Grid container spacing={2}>
                        {/* Dish Information */}
                        <Grid item xs={12}>
                            <Paper elevation={1} sx={{ p: 2 }}>
                                <Typography variant="h6" gutterBottom>Dish Information</Typography>
                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box>
                                        <strong>ID:</strong> {dishDetails.id}
                                        <br />
                                        <strong>Name:</strong> {dishDetails.name}
                                    </Box>
                                    {dishDetails.photo && (
                                        <Box sx={{ ml: 2 }}>
                                            <img
                                                src={dishDetails.photo}
                                                alt={dishDetails.name}
                                                style={{ width: '150px', height: 'auto', borderRadius: '4px' }}
                                            />
                                        </Box>
                                    )}
                                </Typography>

                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'right' }}>
                                    <strong>Details:</strong>  {dishDetails.details}
                                    <IconButton
                                        aria-label="copy details"
                                        onClick={() => {
                                            if (dishDetails.details) {
                                                navigator.clipboard.writeText(dishDetails.details)
                                                    .then(() => {
                                                        setSnackbar({ open: true, message: 'Details copied to clipboard!', severity: 'success' });
                                                    })
                                                    .catch(() => {
                                                        setSnackbar({ open: true, message: 'Failed to copy details.', severity: 'error' });
                                                    });
                                            }
                                        }}
                                        size="small"
                                        sx={{ ml: 1 }}
                                    >
                                        <ContentCopyIcon fontSize="small" />
                                    </IconButton>
                                </Typography>
                                <Typography variant="body1" sx={{ display: 'flex', alignItems: 'right', flexDirection: 'column' }}>
                                    <strong>Ingredients:</strong>
                                    <Box sx={{ display: 'flex', alignItems: 'right', mt: 1, width: '100%' }}>
                                        <Autocomplete
                                            multiple
                                            freeSolo
                                            options={allIngredients}
                                            value={selectedIngredients}
                                            onChange={(_, newValue) => setSelectedIngredients(newValue)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    size="small"
                                                    placeholder="Type to search ingredients..."
                                                />
                                            )}
                                            sx={{ flex: 1 }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleUpdateIngredients}
                                            sx={{ ml: 2 }}
                                        >
                                            Update
                                        </Button>
                                    </Box>
                                </Typography>
                            </Paper>
                        </Grid>
                    </Grid>

                    {/* Additional Sections */}
                </>
            ) : (
                <Typography variant="h6">Select a dish to view details</Typography>
            )}
        </Box>
    );

    const body = (
        <Grid
            container
            spacing={2}
            sx={{ height: 'calc(100vh - 64px)' }}
        >
            <Grid item xs={12} md={4} sx={{ height: '100%' }}>
                {dishList}
            </Grid>
            <Grid item xs={12} md={8} sx={{ height: '100%' }}>
                {dishDetailsSection}
            </Grid>
        </Grid>
    );

    return (
        <>
            <Layout body={body} title="Dish Management" />
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default DishManagement;