import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import Layout from '../../layout/layout';
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  ListItemSecondaryAction,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CancelIcon from '@mui/icons-material/Cancel';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import SubscriptionDetailsDialog from './components/SubscriptionDetailsDialog';

function UserManagement() {
  const [query, setQuery] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [openRenewDialog, setOpenRenewDialog] = useState(false);

  const handleSearch = async (e) => {
    const value = e.target.value;
    setQuery(value);
    if (value.length > 2) {
      try {
        const response = await axios.get(`user/search/?q=${value}`);
        setUsers(response.data.users);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    } else {
      setUsers([]);
    }
  };

  const handleUserClick = (user) => {
    setSelectedUser(user);
  };

  const navigate = useNavigate();

  // Fetch user details when selectedUser changes
  useEffect(() => {
    if (selectedUser) {
      const fetchUserDetails = async () => {
        try {
          const response = await axios.get(`user/info/?user_id=${selectedUser.id}`);
          setUserDetails(response.data);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      };
      fetchUserDetails();
    }
  }, [selectedUser]);

  const handleRenewClick = () => {
    setOpenRenewDialog(true);
  };

  const handleRenewConfirm = () => {
    setOpenRenewDialog(false);
    navigate(`/renew-subscription?userId=${userDetails.id}`);
  };

  const theme = useTheme();

  const userList = (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        paddingRight: 1, // Optional: Add some padding for better UX
      }}
    >
      <TextField
        fullWidth
        value={query}
        onChange={handleSearch}
        placeholder="Search by name, ID, phone number, or email"
        variant="outlined"
        margin="normal"
      />
      <List>
        {users.map((user) => (
          <ListItem
            button
            key={user.id}
            onClick={() => handleUserClick(user)}
            selected={selectedUser && selectedUser.id === user.id}
          >
            <ListItemText
              primary={user.full_name}
              secondary={`Phone: ${user.phone_number}`}
            />
            {user.remaining_subscriptions > 0 && (
              <ListItemSecondaryAction>
                <CheckCircleIcon color="primary" />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const userDetailsSection = (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        padding: 2,
      }}
    >
      {userDetails ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h5">{userDetails.name}</Typography>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ShoppingCartIcon />}
              onClick={() => navigate(`/buy-subscription?userId=${userDetails.id}`)}
            >
              Buy Subscription
            </Button>
          </Box>
          <Divider sx={{ my: 1 }} />

          <Grid container spacing={2}>
            {/* Personal Information */}
            <Grid item xs={4}>
              <Paper elevation={1} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Personal Information</Typography>
                <Typography variant="body1">
                  <strong>ID:</strong> {userDetails.id}
                </Typography>
                <Typography variant="body1">
                  <strong>Name:</strong> {userDetails.name}
                </Typography>
                <Typography variant="body1">
                  <strong>Email:</strong> {userDetails.email}
                </Typography>
                <Typography variant="body1">
                  <strong>Phone:</strong> {userDetails.phone}
                </Typography>
              </Paper>
            </Grid>

            {/* Demographics */}
            <Grid item xs={4}>
              <Paper elevation={1} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Demographics</Typography>
                <Typography variant="body1">
                  <strong>Age:</strong> {userDetails.age}
                </Typography>
                <Typography variant="body1">
                  <strong>Gender:</strong> {userDetails.gender}
                </Typography>
                <Typography variant="body1">
                  <strong>Birthday:</strong> {userDetails.birthday}
                </Typography>
                <Typography variant="body1">
                  <strong>Area:</strong> {userDetails.area}
                </Typography>
                <Typography variant="body1">
                  <strong>Driver:</strong> {userDetails.driver}
                </Typography>
              </Paper>
            </Grid>

            {/* Subscription Details */}
            <Grid item xs={4}>
              <Paper elevation={1} sx={{ p: 2 }}>
                <Typography variant="h6" gutterBottom>Subscription Details</Typography>
                <Typography variant="body1">
                  <strong>Package:</strong> {userDetails.package}
                </Typography>
                <Typography variant="body1">
                  <strong>Remaining Subscriptions:</strong> {userDetails.remaining_subscriptions}
                </Typography>
                <Typography variant="body1">
                  <strong>Total Remaining Days:</strong> {userDetails.remaining_days}
                </Typography>
                <Typography variant="body1">
                  <strong>End Date:</strong> {userDetails.end_date}
                </Typography>
                <Typography variant="body1">
                  <strong>Joined:</strong> {userDetails.joined}
                </Typography>
                
                {userDetails.subscriptions?.some(sub => sub.status === 'Running') && (
                  <>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AutorenewIcon />}
                      onClick={handleRenewClick}
                      sx={{ mt: 2 }}
                    >
                      Renew Subscription
                    </Button>
                    <SubscriptionDetailsDialog
                      open={openRenewDialog}
                      onClose={() => setOpenRenewDialog(false)}
                      onConfirm={handleRenewConfirm}
                      subscriptionId={userDetails.subscriptions.find(sub => sub.status === 'Running').id}
                      title="Renew Subscription"
                      confirmText="Confirm Renewal"
                      message="Are you sure you want to renew this subscription?"
                    />
                  </>
                )}
              </Paper>
            </Grid>
          </Grid>

          {/* Display total number of subscriptions */}
          <Typography variant="body1" sx={{ mt: 2 }}>
            <strong>Total Subscriptions:</strong> {userDetails.subscriptions ? userDetails.subscriptions.length : 0}
          </Typography>

          {/* Subscriptions Table */}
          {userDetails.subscriptions && userDetails.subscriptions.length > 0 ? (
            <>
              <Typography variant="h6" sx={{ mt: 2 }}>Subscriptions</Typography>
              <TableContainer 
                component={Paper} 
                sx={{ 
                  maxHeight: 400,
                  overflow: 'auto',
                  '& .MuiTableCell-sticky': {
                    backgroundColor: (theme) => theme.palette.background.paper,
                    borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
                  }
                }}
              >
                <Table stickyHeader aria-label="subscriptions table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Start Date</TableCell>
                      <TableCell>End Date</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Remaining</TableCell>
                      <TableCell>Package</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userDetails.subscriptions.map((subscription, index) => (
                      <TableRow key={index}>
                        <TableCell>{subscription.start_date}</TableCell>
                        <TableCell>{subscription.end_date || 'N/A'}</TableCell>
                        <TableCell>{subscription.duration} days</TableCell>
                        <TableCell>{subscription.remaining_days} days</TableCell>
                        <TableCell>{subscription.package}</TableCell>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {subscription.status === 'Pending' && (
                              <HourglassEmptyIcon color="secondary" />
                            )}
                            {subscription.status === 'Running' && (
                              <CheckCircleIcon color="primary" />
                            )}
                            {subscription.status === 'Expired' && (
                              <CancelIcon color="error" />
                            )}
                            <Typography variant="body2" sx={{ ml: 1 }}>
                              {subscription.status}
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <Typography variant="body1" sx={{ mt: 2 }}>
              No subscriptions found.
            </Typography>
          )}
        </>
      ) : (
        <Typography variant="h6">Select a user to view details</Typography>
      )}
    </Box>
  );

  const body = (
    <Grid
      container
      spacing={2}
      sx={{ height: 'calc(100vh - 64px)' }} // Adjust height as needed
    >
      <Grid item xs={3} sx={{ height: '100%' }}>
        {userList}
      </Grid>
      <Grid item xs={9} sx={{ height: '100%' }}>
        {userDetailsSection}
      </Grid>
    </Grid>
  );

  return <Layout body={body} title="User Management" />;
}

export default UserManagement;