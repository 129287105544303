import { Button } from "@mui/material";
import Layout from "../../layout/layout";
import { useNavigate } from "react-router-dom";

function KitchenManagement() {
  const navigate = useNavigate();

  const body = (
    <div>
      <Button
        variant="contained"
        color="primary"
        sx={{ mr: 2, mb: 2 }}
        onClick={() => {
          navigate("/dishes/v2/");
        }}
      >
        Dish List
          </Button>
          <Button
              variant="contained"
              color="primary"
              sx={{ mr: 2, mb: 2 }}
                onClick={() => {
                    navigate("/ingredient-management/");
              }}
          >
              Ingredient List
            </Button>   
    </div>
  );

  return <Layout body={body} title="Kitchen Management" />;
}

export default KitchenManagement;