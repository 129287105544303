import React, { useState, useEffect } from 'react';
import axios from '../../../api/axios';
import Layout from '../../../layout/layout';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Chip,
  CircularProgress,
  Snackbar,
  Alert,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

function IngredientsListPage() {
  const [ingredients, setIngredients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  const navigate = useNavigate();

  useEffect(() => {
    const fetchIngredients = async () => {
      setLoading(true);
      try {
        const response = await axios.get('ingredient/list/');
        setIngredients(response.data);
      } catch (err) {
        console.error('Error fetching ingredients:', err);
        setError('Failed to fetch ingredients.');
        setSnackbar({ open: true, message: 'Failed to fetch ingredients.', severity: 'error' });
      } finally {
        setLoading(false);
      }
    };

    fetchIngredients();
  }, []);

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  const body = (
    <Box sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        Ingredients List
      </Typography>
      <Divider sx={{ mb: 2 }} />

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <List>
          {ingredients.map((ingredient) => (
            <React.Fragment key={ingredient.id}>
              <ListItem
                secondaryAction={
                  <Chip
                    label={`Dishes: ${ingredient.dish_count || 0}`}
                    color="primary"
                    variant="outlined"
                  />
                }
              >
                <ListItemText primary={ingredient.name} />
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>
      )}
    </Box>
  );

  return (
    <>
      <Layout body={body} title="Ingredients Management" />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default IngredientsListPage;